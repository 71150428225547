import React from "react"
import { StoryGroup } from "components"

const eBooks = [
  {
    title: "React Routing",
    url: "https://learnreactui.dev/contents/react-routing",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2F41368524-7edd-4e9f-9fce-6545219bccfc%2Fcovers%2FCover.png",
  },
]

const LinkMap = {
  "React Router": "389107fec4fd",
  Navigations: "389107fec4fd",
  Switch: "389107fec4fd",
  Link: "389107fec4fd",
  History: "389107fec4fd",
  "Push State": "389107fec4fd",
  "Dynamic Pages": "389107fec4fd",
  withRouter: "389107fec4fd",

  "Lazy Loading": "7bb39ff7d773",
  "React.Lazy": "7bb39ff7d773",
  Suspense: "7bb39ff7d773",
  "Route props Component": "7bb39ff7d773",

  "Dynamic Page": "7a99a7dcd05d",
  "Dynamic Routing": "7a99a7dcd05d",
  "exact path=false": "7a99a7dcd05d",
  "nested pages": "7a99a7dcd05d",

  Routing: "99b1b1ade274",
  BrowserRouter: "99b1b1ade274",
  HashRouting: "99b1b1ade274",
  StaticRouter: "99b1b1ade274",
  NativeRouter: "99b1b1ade274",
  MemoryRouter: "99b1b1ade274",
  "Routes/Route": "99b1b1ade274",
  "<Link>": "99b1b1ade274",
  "<NavLink>": "99b1b1ade274",
  "<Navigation>": "99b1b1ade274",
  "<Outlet>": "99b1b1ade274",

  "Basic Routing": "f9b36379828f",
  "URL Params": "f9b36379828f",
  "Nested Routes": "f9b36379828f",

  "Lazy Loading2": "164d404716e6",
  "Code Splitting": "f9b36379828f",
  "Pass Props": "f9b36379828f",
  "Pass State": "f9b36379828f",

  "404 Page": "2e98056a22c8",
  "Ambiguous Matching": "2e98056a22c8",
  "<Switch>": "2e98056a22c8",
  "<Routes>": "2e98056a22c8",

  "<AuthRoute>": "6fafce26681a",
  "<Prompt>": "6fafce26681a",
}

const storiesBasics = [
  {
    title: "React Router - 1 (Temel Kavramlar)",
    postId: "99b1b1ade274",
  },
  {
    title: "React Router - 2 (Routing & Paramater Types)",
    postId: "f9b36379828f",
  },
  {
    title: "React Router - 3 (Data Passing & Lazy Loading)",
    postId: "164d404716e6",
  },
  {
    title: "React Router - 4 (Error Handling, 404 Pages)",
    postId: "2e98056a22c8",
  },
  {
    title: "React Router — 5 (Private, Public Pages)",
    postId: "6fafce26681a",
  },
  {
    title: "React Router — 6 (Authorization)",
    postId: "33aca65c0c36",
  },
  {
    title: "React Router — 7 (Highlight Active Tab/Breadcrumb)",
    postId: "6788b09b8966",
  },
]

const storiesPractice = [
  {
    title: "React’da Sayfalar Arası Navigasyon",
    postId: "389107fec4fd",
  },
  {
    title: "Load Lazy When Routing",
    postId: "7bb39ff7d773",
  },
  {
    title: "React Router — 5 to 6 Migration",
    postId: "7375ad32ec28",
  },
]

const storiesGroup = [
  {
    title: "Routing",
    storyPart: storiesBasics,
  },
  {
    title: "Pratikler",
    storyPart: storiesPractice,
  },
]

export const context = {
  stories: storiesGroup,
  linkMap: LinkMap,
  title: "React Routing",
  path: "react-router",
}

const ReactRouterPage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      stories={context.stories}
      linkMap={context.linkMap}
      eBooks={eBooks}
    />
  )
}

export default ReactRouterPage
